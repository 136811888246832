import GoToTop from "../../components/GoToTop/GoToTop";
import { Link } from "react-router-dom";
import tdream from "../../assets/images/UO+KNITS-05.jpg";
import shape from "../../assets/images/shape-2.jpeg";
import ua from "../../assets/images/ua-1.jpeg";
import process from "../../assets/images/process-2.jpeg";

let Apparel = () => {
  return (
    <main className="main-container">
      <section className="main projects">
        <span className="feature__title">
          <h1 className="feature__title__text">FASHION DESIGN</h1>
          <div className="feature__title__line" />
        </span>
        <article className="projects-container">
          <div className="project">
            <div className="project-image">
              <Link to="/transcendent" className="project-image">
                <img
                  src={tdream}
                  className="project-image__file"
                  alt="project thumbnail"
                />
              </Link>
            </div>
            <div className="project-text">
              <h4>TRANSCENDENT DREAM</h4>
              <p className="project-text__description">
                This FALL 19 capsule is a personal project, focused on comfort,
                warm neutrals, and cozy knits. It includes a moodboard, color
                palette, fabrics & trims palette, and designs with technical
                specs.
              </p>
              <div className="project__links">
                <Link to="/transcendent">
                  <button className="button">View more</button>
                </Link>
              </div>
            </div>
          </div>
          <div className="project">
            <div className="project-image">
              <Link to="/shapeactive" className="project-image">
                <img
                  src={shape}
                  className="project-image__file"
                  alt="project thumbnail"
                />
              </Link>
            </div>
            <div className="project-text">
              <h4>SHAPE ACTIVE</h4>
              <p className="project-text__description">
                This is a selection of pieces I designed for Shape Active, a
                women's performance and lifestyle brand. I also designed the
                prints and graphics on the garments using different techniques
                such as sublimation and reflective foil application.
              </p>
              <div className="project__links">
                <Link to="/shapeactive">
                  <button className="button">View more</button>
                </Link>
              </div>
            </div>
          </div>
          <div className="project">
            <div className="project-image">
              <Link to="/underarmour" className="project-image">
                <img
                  src={ua}
                  className="project-image__file"
                  alt="project thumbnail"
                />
              </Link>
            </div>
            <div className="project-text">
              <h4>UNDER ARMOUR</h4>
              <p className="project-text__description">
                These are pieces I designed at Under Armour. Some designs are
                heavily influenced by the fabric technologies developed for
                those particular programs. Thought-out trims and sewing
                techniques enhance functionality and maximize comfort. Other
                styles showcase the partnership between the graphic and apparel
                design teams to bring additional novelty to the line.
              </p>
              <div className="project__links">
                <Link to="/underarmour">
                  <button className="button">View more</button>
                </Link>
              </div>
            </div>
          </div>
          <div className="project">
            <div className="project-image">
              <Link to="/process" className="project-image">
                <img
                  src={process}
                  className="project-image__file"
                  alt="project thumbnail"
                />
              </Link>
            </div>
            <div className="project-text">
              <h4>PROCESS</h4>
              <p className="project-text__description">
                This section shows part of the design process, including
                sketches, variations, and the research used as reference and
                inspiration. There's also some experimentation done through
                draping on the body and origami exploration applied to fabrics.
              </p>
              <div className="project__links">
                <Link to="/process">
                  <button className="button">View more</button>
                </Link>
              </div>
            </div>
          </div>
        </article>
      </section>
      <GoToTop />
    </main>
  );
};

export default Apparel;
